import { PAGE_ROUTES } from '@enums/page-routes.enum';

export const menuItems: { link: string; icon: string; titleKey: string }[] = [
  { link: '/', icon: 'element-11', titleKey: 'dashboard.title' },
  // {
  //   link: `/${PAGE_ROUTES.LOYALTY_PROGRAM}`,
  //   icon: 'abstract-38',
  //   titleKey: 'loyaltyProgram.title',
  // },
  // {
  //   link: `/${PAGE_ROUTES.MARKETING_CAMPAIGNS}`,
  //   icon: 'data',
  //   titleKey: 'marketingCampaigns.title',
  // },
  // {
  //   link: `/${PAGE_ROUTES.REPORTS}`,
  //   icon: 'tablet-book',
  //   titleKey: 'reports.title',
  // },
  {
    link: `/${PAGE_ROUTES.LOCATIONS}`,
    icon: 'geolocation',
    titleKey: 'locations.title',
  },
  {
    link: `/${PAGE_ROUTES.USERS}`,
    icon: 'user-square',
    titleKey: 'users.title',
  },
  {
    link: `/${PAGE_ROUTES.CUSTOMERS}`,
    icon: 'people',
    titleKey: 'customers.title',
  },
  {
    link: `/${PAGE_ROUTES.EVENTS}`,
    icon: 'abstract-3',
    titleKey: 'events.title',
  },
  {
    link: `/${PAGE_ROUTES.OFFERS}`,
    icon: 'book-open',
    titleKey: 'offers.title',
  },
  {
    link: `/${PAGE_ROUTES.PRODUCTS}`,
    icon: 'package',
    titleKey: 'products.title',
  },
  // {
  //   link: `/${PAGE_ROUTES.CONTENT_LIBRARY}`,
  //   icon: 'folder',
  //   titleKey: 'contentLibrary.title',
  // },
  {
    link: `/${PAGE_ROUTES.BRAND}`,
    icon: 'lots-shopping',
    titleKey: 'brand.title',
  },
  // {
  //   link: `/${PAGE_ROUTES.WALLET}`,
  //   icon: 'two-credit-cart',
  //   titleKey: 'wallet.title',
  // },
  {
    link: `/${PAGE_ROUTES.NOTIFICATIONS_CENTER}`,
    icon: 'notification-on',
    titleKey: 'notifications.title',
  },
  {
    link: `/${PAGE_ROUTES.ACCOUNTS}`,
    icon: 'profile-user',
    titleKey: 'accounts.title',
  },
];
