<div class="d-flex flex-column h-100">
  <div class="scroll-y my-5 mx-3 d-flex flex-column flex-grow-1">
    <div
      *transloco="let t"
      class="menu menu-column menu-rounded menu-sub-indention fw-semibold fs-6 flex-grow-1"
    >
      <div class="menu-item" *ngFor="let item of menuItems">
        <a
          class="menu-link without-sub"
          [routerLink]="[item.link]"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: item.link === '/' }"
          (click)="activeOffcanvas.close()"
        >
          <span class="menu-icon">
            <ui-keenicon [name]="item.icon" class="fs-2"></ui-keenicon>
          </span>
          <span class="menu-title text-nowrap">{{ t(item.titleKey) }}</span>
        </a>
      </div>
    </div>
  </div>
  <div class="app-sidebar-footer mt-auto flex-column-auto pt-2 pb-6 px-6">
    <app-sidebar-footer></app-sidebar-footer>
  </div>
</div>
