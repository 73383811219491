import { ChangeDetectionStrategy, Component } from '@angular/core';
import { KeeniconComponent } from '@ui-kit';
import { TranslocoPipe } from '@jsverse/transloco';
import { RouterLink } from '@angular/router';
import { PAGE_ROUTES } from '@enums/page-routes.enum';

@Component({
  selector: 'app-sidebar-footer',
  standalone: true,
  imports: [KeeniconComponent, TranslocoPipe, RouterLink],
  templateUrl: './sidebar-footer.component.html',
  styleUrl: './sidebar-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarFooterComponent {
  protected readonly PAGE_ROUTES = PAGE_ROUTES;
}
