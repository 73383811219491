<div class="overflow-hidden">
  <a routerLink="/" class="d-flex">
    <img
      alt="Logo"
      src="./assets/media/main/logo.png"
      class="h-50px app-sidebar-logo-default"
    />

    <img
      alt="Logo"
      src="./assets/media/main/min-logo.png"
      class="h-50px app-sidebar-logo-minimize position-relative"
      style="left: -4px"
    />
  </a>
</div>

<div
  class="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate"
  (click)="onSidebarToggleClick()"
>
  <ui-keenicon name="double-left" class="fs-2 rotate-180"></ui-keenicon>
</div>
