import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { PAGE_ROUTES } from '@enums/page-routes.enum';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { menuItems } from '../../data/menu-data';
import { NgForOf } from '@angular/common';
import { KeeniconComponent } from '@ui-kit';
import { SidebarFooterComponent } from '@pages/auth/modules/sidebar/partials/sidebar-footer/sidebar-footer.component';

@Component({
  selector: 'app-sidebar-menu',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    TranslocoDirective,
    NgForOf,
    KeeniconComponent,
    SidebarFooterComponent,
  ],
  templateUrl: './sidebar-menu.component.html',
  styleUrl: './sidebar-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarMenuComponent {
  protected readonly PAGE_ROUTES = PAGE_ROUTES;
  menuItems = menuItems;

  constructor(protected activeOffcanvas: NgbActiveOffcanvas) {}
}
