<div class="d-lg-block">
  <div class="d-lg-block p-5 p-lg-0">
    <div
      class="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-600 menu-state-title-primary nav nav-flush fs-5 fw-semibold"
    >
      <div class="menu-item">
        <a class="menu-link nav-link py-3 px-4 px-xxl-6">Home</a>
      </div>
      <div class="menu-item">
        <a
          href="#how-it-works"
          class="menu-link nav-link py-3 px-4 px-xxl-6"
          (click)="activeOffcanvas.close()"
          >How it Works</a
        >
      </div>
      <div class="menu-item">
        <a
          href="#statistic"
          class="menu-link nav-link py-3 px-4 px-xxl-6"
          (click)="activeOffcanvas.close()"
          >Achievements</a
        >
      </div>
      <div class="menu-item">
        <a
          href="#team"
          class="menu-link nav-link py-3 px-4 px-xxl-6"
          (click)="activeOffcanvas.close()"
          >Team</a
        >
      </div>
      <div class="menu-item">
        <a
          href="#pricing"
          class="menu-link nav-link py-3 px-4 px-xxl-6"
          (click)="activeOffcanvas.close()"
          >Pricing</a
        >
      </div>
      <div class="menu-item">
        <a
          href="#reviews"
          class="menu-link nav-link py-3 px-4 px-xxl-6"
          (click)="activeOffcanvas.close()"
          >Reviews</a
        >
      </div>
    </div>
  </div>
</div>
