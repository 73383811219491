import {
  Component,
  ChangeDetectionStrategy,
  Renderer2,
  ElementRef,
  OnInit,
} from '@angular/core';
import { SidebarLogoComponent } from './partials/sidebar-logo/sidebar-logo.component';
import { SidebarMenuComponent } from './partials/sidebar-menu/sidebar-menu.component';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [SidebarLogoComponent, SidebarMenuComponent],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  ngOnInit() {
    this.initializeClasses();
  }

  initializeClasses() {
    const sidebarElement = this.el.nativeElement;
    this.renderer.addClass(sidebarElement, 'app-sidebar');
    this.renderer.addClass(sidebarElement, 'flex-column');
  }
}
