import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LayoutService } from '@services/layout.service';
import { KeeniconComponent } from '@ui-kit';

@Component({
  selector: 'app-sidebar-logo',
  standalone: true,
  imports: [RouterLink, KeeniconComponent],
  templateUrl: './sidebar-logo.component.html',
  styleUrl: './sidebar-logo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarLogoComponent {
  constructor(private layoutService: LayoutService) {}

  onSidebarToggleClick() {
    this.layoutService.toggleSidebarMinimize();
  }
}
