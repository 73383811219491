import { Injectable } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { SidebarComponent } from '@pages/auth/modules/sidebar/sidebar.component';
import { BehaviorSubject } from 'rxjs';
import { LOCAL_STORAGE } from '@enums/local-storage.enum';
import { HeaderMenuComponent } from '@pages/about/modules/header-menu/header-menu.component';

@Injectable()
export class LayoutService {
  private readonly SIDEBAR_MINIMIZE_ATTR = 'data-kt-app-sidebar-minimize';
  private readonly MOBILE_OFFCANVAS_CLASS = 'mobile-sidebar';
  private readonly LAND_MOBILE_OFFCANVAS_CLASS = 'mobile-land-sidebar';
  private readonly LOCAL_STORAGE_KEY = LOCAL_STORAGE.SIDEBAR_MINIMIZE_STATE;
  initialMenuState = this.getInitialMenuState();
  sidebarMinimized$ = new BehaviorSubject<boolean>(this.initialMenuState);

  constructor(private offcanvasService: NgbOffcanvas) {
    this.setInitialSidebarState();
  }

  toggleSidebarMinimize(): void {
    const bodyElement = document.body;
    const currentState = bodyElement.getAttribute(this.SIDEBAR_MINIMIZE_ATTR);
    const newState = currentState === 'on' ? 'off' : 'on';
    this.sidebarMinimized$.next(newState === 'on');
    bodyElement.setAttribute(this.SIDEBAR_MINIMIZE_ATTR, newState);
    localStorage.setItem(this.LOCAL_STORAGE_KEY, newState);
  }

  openMobileMenu() {
    const offcanvasRef = this.offcanvasService.open(SidebarComponent, {
      panelClass: this.MOBILE_OFFCANVAS_CLASS,
    });
    offcanvasRef.componentInstance;
  }

  openLandingMobileMenu() {
    const offcanvasRef = this.offcanvasService.open(HeaderMenuComponent, {
      panelClass: this.LAND_MOBILE_OFFCANVAS_CLASS,
    });
    offcanvasRef.componentInstance;
  }

  private getInitialMenuState(): boolean {
    const savedState = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    return savedState === 'on';
  }

  private setInitialSidebarState(): void {
    const bodyElement = document.body;
    const savedState = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (savedState) {
      bodyElement.setAttribute(this.SIDEBAR_MINIMIZE_ATTR, savedState);
    }
  }
}
